.adminResetPassword {
  display: flex;
  flex-direction: column;
  padding: 2vw 0;
  justify-content: center;
}
.adminResetPassword .token-status-error {
  padding: 25vh 0;
}
.adminResetPassword .title {
  text-align: center;
}
.adminResetPassword .token-status-error {
  text-align: center;
}
.adminResetPassword .form-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.5vw;
  padding: 2vw 7vw;
}
.adminResetPassword .form-group label {
  font-size: 1.5vw;
  font-weight: bold;
}
.adminResetPassword .form-group input {
  padding: 1vw;
  font-size: 1.5vw;
}
.adminResetPassword .form-group .grouping-with-errorbox {
  display: flex;
  flex-direction: column;
  gap: 1vw;
}
.adminResetPassword .form-group .grouping-with-errorbox .error-msg {
  color: rgb(248, 78, 78);
  font-size: 1vw;
}
.adminResetPassword .form-group .submit-button {
  padding: 1vw;
  font-size: 1.5vw;
  cursor: pointer;
}/*# sourceMappingURL=AdminResetPassword.css.map */