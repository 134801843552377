section {
  padding: 2vw;
}

.ratings-and-reviews-head {
  display: flex;
  justify-content: space-between;
}
.ratings-and-reviews-head .product-detail {
  display: flex;
  align-items: center;
  gap: 1vw;
}
.ratings-and-reviews-head .product-detail .image-container {
  width: 10vw;
  height: 8vw;
  border: 0.1vw solid black;
  cursor: pointer;
}
.ratings-and-reviews-head .product-detail .image-container img {
  height: inherit;
  width: inherit;
  -o-object-fit: cover;
     object-fit: cover;
}
.ratings-and-reviews-head .product-detail .name-and-rating {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1vw;
}
.ratings-and-reviews-head .product-detail .name-and-rating .rating-detail {
  display: flex;
  gap: 0.5vw;
}

.rating-section, .review-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1vw;
  border: 1px solid lightgray;
  padding: 1vw;
  margin-top: 2vw;
}
.rating-section textarea, .review-section textarea {
  background: transparent;
  width: 100%;
  resize: none;
  padding: 1vw;
  border: none;
  font-size: 1vw;
}
.rating-section textarea:focus, .review-section textarea:focus {
  outline: none;
}

.review-form {
  display: flex;
  flex-direction: column;
  gap: 1vw 0;
}

.submit-section {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.submit-section button {
  font-size: 1.5vw;
  padding: 0.5vw 3vw;
}

.not-purchased {
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1vw;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .review-section textarea {
    font-size: 3vw;
  }
  .submit-section button {
    font-size: 3vw;
  }
}/*# sourceMappingURL=RatingAndReview.css.map */