.post-container {
  display: flex;
  flex-wrap: wrap;
}

.post {
  width: calc(33.33% - 20px);
  margin: 10px;
}

.post-image {
  width: 100%;
  height: 300px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 8px;
}

.post-caption {
  margin-top: 10px;
}

.load-more-button {
  margin: 20px 0;
  padding: 10px;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.load-more-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (orientation: portrait) {
  .post {
    width: calc(50% - 20px);
  }
}/*# sourceMappingURL=Blog.css.map */