.banner-control-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.5vw;
  padding: 5vw;
}
.banner-control-container label {
  font-weight: bold;
  font-size: 20px;
}
@media (max-width: 768px) {
  .banner-control-container label {
    font-size: 14px;
  }
}
.banner-control-container input {
  padding: 1vw;
  font-size: 1.5vw;
}
.banner-control-container .banner-selector {
  padding: 1vw;
  font-size: 16px;
  border-radius: 10px;
  font-weight: bold;
  width: 75%;
}
@media (max-width: 768px) {
  .banner-control-container .banner-selector {
    font-size: 12px;
  }
}
.banner-control-container .banner-selector option {
  margin: 10px;
}
.banner-control-container .banner-container-grouping {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 1vw;
}
.banner-control-container .banner-container-grouping .banner-error-msg {
  color: rgb(248, 78, 78);
  font-size: 1vw;
}
.banner-control-container .banner-existing-images {
  display: flex;
  flex-wrap: wrap;
  gap: 1vw;
}
.banner-control-container .banner-existing-images .banner-existing-image {
  position: relative;
}
.banner-control-container .banner-existing-images .banner-existing-image img {
  height: 15vw;
  width: auto;
  border: 1px solid black;
}
.banner-control-container .banner-existing-images .banner-existing-image .banner-delete-button {
  position: absolute;
  top: 0;
  right: 0;
}
.banner-control-container .banner-image-thumbnails {
  display: flex;
  gap: 1vw;
  flex-wrap: wrap;
}
.banner-control-container .banner-image-thumbnails .banner-thumbnail {
  position: relative;
}
.banner-control-container .banner-image-thumbnails .thumbnail-remove-button {
  position: absolute;
  top: 0;
  right: 0;
}
.banner-control-container .banner-submit-button {
  padding: 1vw;
  font-size: 1.5vw;
  cursor: pointer;
}/*# sourceMappingURL=AdminBanner.css.map */