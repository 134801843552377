.password-change-container {
  max-width: inherit;
  margin: auto;
  padding: 20px;
  border: 1px solid #ddd;
  background-color: #f8f8ff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.password-change-container .verify-success {
  display: flex;
  align-items: center;
  color: green;
  justify-content: center;
  font-style: oblique;
  gap: 5px;
  font-size: small;
  width: 100%;
  margin-bottom: 10px;
}
.password-change-container .verify-success .verify-success-icon {
  color: green;
}
.password-change-container h4 {
  margin-bottom: 10px;
  font-size: large;
}
.password-change-container p {
  margin-bottom: 20px;
  font-size: small;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.password-change-container p strong {
  font-style: oblique;
}
.password-change-container .password-change-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.password-change-container .password-change-form .form-group {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 250px;
  margin-bottom: 10px;
}
.password-change-container .password-change-form .form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 12px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
.password-change-container .password-change-form .form-group input {
  width: 100%;
  padding: 8px;
  padding-right: 40px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.password-change-container .password-change-form .form-group .icon-eye {
  position: absolute;
  top: 40%;
  right: 10px; /* Adjust the position as needed */
  transform: translateY(-50%, -50%);
  cursor: pointer;
  font-size: 1.2em; /* Adjust the size as needed */
  color: #999; /* Adjust the color as needed */
}
.password-change-container .password-change-form .error-message {
  color: red;
  margin-bottom: 10px;
  display: flex;
}
.password-change-container .password-change-form .password-submit-btn {
  padding: 5px 25px;
  border-radius: 4px;
  width: 100%;
  max-width: 200px;
  cursor: pointer;
}
.password-change-container .password-change-form .password-submit-btn:hover {
  background-color: #f8f8ff;
}
.password-change-container .password-change-form .password-submit-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}/*# sourceMappingURL=PasswordReset.css.map */