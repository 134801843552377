.terms-and-conditions {
  display: flex;
  flex-direction: column;
  padding: 5%;
  padding-top: 3%;
  gap: 10px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
.terms-and-conditions .terms-and-conditions_title {
  text-align: center;
  font-size: 25px;
  margin-bottom: 15px;
}
.terms-and-conditions .terms-and-conditions_subtitle {
  font-size: 20px;
  margin-bottom: 10px;
}
.terms-and-conditions .terms-and-conditions_text {
  font-size: 15px;
  margin-bottom: 5px;
}
.terms-and-conditions ul {
  margin: 10px;
  padding: 0;
  padding-left: 5px;
}
.terms-and-conditions ul li {
  margin: 5px;
}/*# sourceMappingURL=TermsAndConditions.css.map */