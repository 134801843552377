.orders-container {
  padding: 2vw;
}
.orders-container .empty-order {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
}
.orders-container .all-orders {
  display: flex;
  flex-direction: column;
  gap: 1vw;
}
.orders-container .all-orders .order-container .order {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2vw 5vw;
  border: 0.1vw solid lightgray;
}
.orders-container .all-orders .order-container .order .product {
  display: flex;
  flex-direction: column;
  gap: 1vw;
}
.orders-container .all-orders .order-container .order .product .image-container {
  width: 10vw;
  height: auto;
  border: 0.1vw solid black;
}
.orders-container .all-orders .order-container .order .product .image-container img {
  width: inherit;
  height: inherit;
  cursor: pointer;
}
.orders-container .all-orders .order-container .order .price {
  text-align: left;
  width: 10vw;
}
.orders-container .all-orders .order-container .order .quantity {
  text-align: left;
  width: 10vw;
}
.orders-container .all-orders .order-container .order .status {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 20vw;
  gap: 1vw;
}
.orders-container .all-orders .order-container .order .status a {
  font-size: 1.5vw;
  text-decoration: none;
}
.orders-container .all-orders .order-container .additional-data {
  display: flex;
  flex-wrap: wrap;
  height: -moz-max-content;
  height: max-content;
  justify-content: space-around;
  gap: 2vw;
  padding: 1vw 2vw;
  background-color: whitesmoke;
  box-shadow: 0.5px 1px 0.5px 1px rgba(0, 0, 0, 0.2);
}
.orders-container .all-orders .order-container .additional-data .divider {
  display: flex;
  flex-direction: column;
  gap: 1vw;
}
.orders-container .all-orders .order-container .additional-data .address {
  display: flex;
  flex-direction: column;
}
.orders-container .all-orders .order-container .additional-data .btn-cancel-return {
  display: flex;
  align-items: center;
  justify-content: center;
}
.orders-container .all-orders .order-container .additional-data .btn-cancel-return button {
  margin: 20px 0;
  padding: 10px;
  background-color: #e25c5c;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}
.orders-container .all-orders .order-container .additional-data .btn-cancel-return button:hover {
  background-color: #f37e75;
}
@media (orientation: portrait) {
  .orders-container .all-orders .order-container .additional-data .btn-cancel-return button {
    font-size: 8px;
  }
}/*# sourceMappingURL=Orders.css.map */