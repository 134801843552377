.fp-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  padding: 0 25px;
}
.fp-overlay section {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 250px;
  max-width: 500px;
}
.fp-overlay section button {
  border-radius: 5px;
  border: 1px solid grey;
  padding: 5px;
}
.fp-overlay section button:hover {
  padding: 5px 10px;
  background-color: #fff;
}
.fp-overlay section .fp-header {
  display: flex;
  align-items: center;
  background-color: whitesmoke;
  height: 40px;
  padding: 25px;
  position: relative;
  font-size: 18px;
}
.fp-overlay section .fp-header h5 {
  font-size: large;
}
.fp-overlay section .fp-header .fp-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.fp-overlay section .fp-body {
  background-color: #F8F8FF;
  display: flex;
  gap: 10px;
  flex-direction: column;
  height: inherit;
  align-items: center;
  padding: 10px;
}
.fp-overlay section .fp-body .fp-text {
  text-align: center;
  font-weight: bold;
  font-size: inherit;
  padding: 10px 20px;
}
.fp-overlay section .fp-body .fp-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 250px;
}
.fp-overlay section .fp-body .fp-form .fp-input {
  border-radius: 5px;
  border: 1px solid black;
  padding: 5px 10px;
  width: inherit;
}
.fp-overlay section .fp-body .fp-form .fp-input:focus {
  outline: 2px solid darkslateblue;
  border: none;
}
.fp-overlay section .fp-body .fp-form .fp-input::-moz-placeholder {
  color: lightslategrey;
}
.fp-overlay section .fp-body .fp-form .fp-input::placeholder {
  color: lightslategrey;
}
.fp-overlay section .fp-body .fp-form .fp-submit {
  width: 150px;
}
.fp-overlay section .fp-body .fp-success {
  color: green;
}
.fp-overlay section .fp-body .fp-success .fp-success-icon {
  color: green;
}
.fp-overlay section .fp-body .fp-error {
  color: red;
}
.fp-overlay section .fp-footer {
  display: flex;
  padding: 10px 25px;
  background-color: whitesmoke;
  justify-content: space-between;
}
.fp-overlay section .fp-footer h5 {
  width: 75px;
  font-weight: bold;
  cursor: pointer;
  font-size: medium;
}/*# sourceMappingURL=ForgotPassword.css.map */