.loc-restriction {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-wrap: wrap;
  padding: 0 2vw;
  font-weight: bold;
  gap: 1vh;
}
.loc-restriction p {
  font-size: larger;
}/*# sourceMappingURL=App.css.map */