.product-control-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.5vw;
  padding: 5vw;
}
.product-control-container label {
  font-weight: bold;
  font-size: 20px;
}
@media (max-width: 768px) {
  .product-control-container label {
    font-size: 12px;
  }
}
.product-control-container input,
.product-control-container textarea,
.product-control-container select,
.product-control-container option {
  padding: 1vw;
  font-size: 1.5vw;
  border-radius: 5px;
}
.product-control-container .grouping-with-errorbox {
  display: flex;
  flex-direction: column;
  gap: 1vw;
}
.product-control-container .grouping-with-errorbox .error-msg {
  color: rgb(248, 78, 78);
  font-size: 1vw;
}
.product-control-container .grouping-with-errorbox em {
  font-size: 12px;
}
@media (max-width: 768px) {
  .product-control-container .grouping-with-errorbox em {
    font-size: 10px;
  }
}
.product-control-container .division {
  display: flex;
  gap: 1vw;
}
.product-control-container .division div {
  flex: 1;
}
.product-control-container .image-thumbnails {
  display: flex;
  gap: 1vw;
  flex-wrap: wrap;
}
.product-control-container .image-thumbnails .thumbnail {
  position: relative;
}
.product-control-container .image-thumbnails .thumbnail img {
  height: 12vw;
  width: auto;
  border: 1px solid black;
  cursor: grab;
}
.product-control-container .image-thumbnails .thumbnail .remove-button {
  position: absolute;
  top: 0;
  right: 0;
}
.product-control-container .submit-button {
  padding: 1vw;
  font-size: 1.5vw;
  cursor: pointer;
}/*# sourceMappingURL=ProductControl.css.map */