.navbar_main {
  height: auto;
  background-color: whitesmoke;
}
.navbar_main .main_menu {
  z-index: 999;
  padding-bottom: 1vw;
}
.navbar_main .main_menu .menu-icons {
  padding: 0 3vw;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.navbar_main .main_menu .menu-icons .left-menu {
  display: flex;
  align-items: center;
  gap: 3vw;
  z-index: 9;
  padding-top: 2vw;
}
.navbar_main .main_menu .menu-icons .left-menu .mobile-menu-icon {
  display: none;
}
.navbar_main .main_menu .menu-icons .left-menu .search {
  position: relative;
  display: flex;
  align-items: center;
  gap: 1vw;
  padding-right: 1vh;
}
.navbar_main .main_menu .menu-icons .left-menu .search .search_icon img {
  width: 1.5vw;
  filter: invert(100%);
}
.navbar_main .main_menu .menu-icons .left-menu .search .search_icon:hover + .search-input,
.navbar_main .main_menu .menu-icons .left-menu .search .search-input:focus {
  opacity: 1;
  pointer-events: auto;
  transition: opacity 1s;
}
.navbar_main .main_menu .menu-icons .left-menu .search .search-input {
  opacity: 0;
  transition: 1s;
  border: none;
  width: 15vw;
  border-radius: 5px;
  padding: 0.5vw 1vw;
  background-color: transparent;
  outline: 2px solid #c7b199;
  color: #c7b199;
}
.navbar_main .main_menu .menu-icons .left-menu .search .search-input::-moz-placeholder {
  font-size: 1vw;
}
.navbar_main .main_menu .menu-icons .left-menu .search .search-input::placeholder {
  font-size: 1vw;
}
.navbar_main .main_menu .menu-icons .left-menu .search .search-input :focus + .search-input {
  display: block;
}
.navbar_main .main_menu .menu-icons .left-menu .search-results {
  position: absolute;
  top: 120%;
  left: 0;
  right: 0;
  background-color: white;
  border-radius: 0 0 5px 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 5;
  display: flex;
  flex-direction: column;
  gap: 1vh;
  max-height: 40vh;
  overflow-y: auto;
  padding: 1vw 0;
}
.navbar_main .main_menu .menu-icons .left-menu .search-results .search-result {
  display: flex;
  align-items: center;
  gap: 2vw;
  padding: 1vh 2vw;
  cursor: pointer;
  background-color: whitesmoke;
}
.navbar_main .main_menu .menu-icons .left-menu .search-results .search-result img {
  width: 6vw;
}
.navbar_main .main_menu .menu-icons .left-menu .search-results .search-result span {
  font-size: 1.5vw;
}
.navbar_main .main_menu .menu-icons .left-menu .no-search-results {
  position: absolute;
  top: 120%;
  left: 0;
  right: 0;
  background-color: white;
  border-radius: 0 0 5px 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 5;
  padding: 1vw;
}
.navbar_main .main_menu .menu-icons .left-menu .no-search-results span {
  font-size: 2vw;
}
.navbar_main .main_menu .menu-icons .center-menu {
  position: sticky;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.navbar_main .main_menu .menu-icons .center-menu .menu-logo-container {
  padding: 1vw 3vw;
}
.navbar_main .main_menu .menu-icons .center-menu .menu-logo-container img {
  width: 6vw;
  cursor: pointer;
}
.navbar_main .main_menu .menu-icons .center-menu .menu-list {
  padding: 0;
  margin: 0;
  display: flex;
  gap: 3vw;
  align-items: center;
}
.navbar_main .main_menu .menu-icons .center-menu .menu-list li {
  list-style: none;
  font-size: 0.75vw;
  color: #c7b199;
}
.navbar_main .main_menu .menu-icons .center-menu .menu-list li a {
  text-decoration: none;
  color: #c7b199;
}
.navbar_main .main_menu .menu-icons .center-menu .menu-list li .arrow {
  font-size: 1vw;
  vertical-align: bottom;
}
.navbar_main .main_menu .menu-icons .right-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2vw;
  gap: 1vw;
}
.navbar_main .main_menu .menu-icons .right-menu .cart_icon {
  cursor: pointer;
  z-index: 5;
  position: relative;
}
.navbar_main .main_menu .menu-icons .right-menu .cart_icon img {
  width: 1.5vw;
  filter: invert(100%);
}
.navbar_main .main_menu .menu-icons .right-menu .cart_icon .cartCount {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 5px;
  font-size: 12px;
}
.navbar_main .main_menu .menu-icons .right-menu .user_box {
  display: flex;
  flex-direction: column;
  z-index: 5;
  margin: 0;
  padding: 0;
  font-size: 2.25vw;
  position: relative;
}
.navbar_main .main_menu .menu-icons .right-menu .user_box .user_icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5vw;
}
.navbar_main .main_menu .menu-icons .right-menu .user_box .user_icon img {
  width: 1.5vw;
  filter: invert(100%);
}
.navbar_main .main_menu .menu-icons .right-menu .user_box .user_icon .user-name {
  border: 1px solid whitesmoke;
  border-radius: 5px;
  color: black;
  background-color: white;
  padding: 0.25vw;
}
.navbar_main .main_menu .menu-icons .right-menu .user_box .account-options {
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 0.25vw;
  position: absolute;
  top: 110%;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 5;
  border-radius: 0.25vw;
  gap: 0.5vw;
}
.navbar_main .main_menu .menu-icons .right-menu .user_box .account-options button {
  font-size: 1vw;
  width: 7vw;
  outline: none;
  border: none;
  background-color: white;
  cursor: pointer;
  font-weight: bold;
}
.navbar_main .main_menu .shoplist-category {
  background-color: white;
  box-shadow: rgba(5, 5, 5, 0.918) 0px 5px 15px;
  padding: 0.5vw 1vw;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1vw 1vw;
}
.navbar_main .main_menu .shoplist-category ul {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  flex-wrap: wrap;
  gap: 1vw;
  padding: 0;
}
.navbar_main .main_menu .shoplist-category ul li {
  flex-basis: 30%;
  text-align: center;
}
.navbar_main .main_menu .shoplist-category ul li a {
  text-decoration: none;
  color: black;
}
.navbar_main .main_menu .shoplist-category ul li a .shoplist-category-item {
  background-color: white;
}
.navbar_main .sideBar {
  display: none;
}
.navbar_main .account-mobile {
  display: none;
}
.navbar_main .username-mobile {
  display: none;
}

@media (max-width: 768px) {
  .navbar_main .sideBar {
    display: block;
    position: fixed;
    top: 0;
    left: -100%; /* Start with the sidebar off-screen to the left */
    height: 100%;
    width: 100%; /* Adjust the width as needed */
    background-color: whitesmoke;
    z-index: 10;
    transition: left 0.3s ease-in-out; /* Use left for smooth transition */
  }
  .navbar_main .sideBar.open {
    left: 0; /* Bring the sidebar into view */
  }
  .navbar_main .sideBar .sidebarContent {
    display: flex;
    flex-direction: column;
    padding-top: 5vw;
    height: inherit;
  }
  .navbar_main .sideBar .sidebarContent .sidebar-logo {
    text-align: center;
  }
  .navbar_main .sideBar .sidebarContent .sidebar-logo img {
    width: 20vw;
  }
  .navbar_main .sideBar .sidebarContent .shoplist-category {
    height: auto;
    padding: 5vw 0;
    background-color: #c7b199;
  }
  .navbar_main .sideBar .sidebarContent .shoplist-category ul {
    display: flex;
    height: inherit;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    padding: 0;
    align-items: center;
    gap: 7vh;
    list-style: none;
    font-size: 4vw;
  }
  .navbar_main .sideBar .sidebarContent .shoplist-category ul li a {
    text-decoration: none;
    color: black;
  }
  .navbar_main .sideBar .sidebarContent .shoplist-category ul li a .shoplist-category-item {
    background-color: #c7b199;
  }
  .navbar_main .sideBar .sidebarContent .menu-list {
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 4vw;
    list-style: none;
    padding: 0 5vw;
  }
  .navbar_main .sideBar .sidebarContent .menu-list li {
    display: flex;
    align-items: center;
    color: #c7b199;
  }
  .navbar_main .sideBar .sidebarContent .menu-list li a {
    text-decoration: none;
    color: #c7b199;
  }
  .navbar_main .sideBar .sidebarContent .menu-list li .arrow {
    font-size: 3vw;
    vertical-align: bottom;
    color: #c7b199;
  }
  .navbar_main .sideBar .sidebarContent .account-info {
    display: flex;
    flex-direction: column;
    padding: 0 5vw;
    position: absolute;
    bottom: 2%;
    left: 0;
    right: 0;
  }
  .navbar_main .sideBar .sidebarContent .account-info .username-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3vw;
    font-size: 3vw;
    font-style: oblique;
    color: #c7b199;
  }
  .navbar_main .sideBar .sidebarContent .account-info .username-mobile span {
    font-weight: bold;
    font-style: normal;
  }
  .navbar_main .sideBar .sidebarContent .account-info .account-mobile {
    display: flex;
    padding: 2vw 2vw;
    gap: 5vw;
  }
  .navbar_main .sideBar .sidebarContent .account-info .account-mobile button {
    font-size: 5vw;
    background-color: black;
    color: white;
    border: 1px solid black;
    padding: 2vw 5vw;
    border-radius: 5px;
  }
  .navbar_main .sideBar .sidebarContent .account-info .account-mobile .orders-mobile,
  .navbar_main .sideBar .sidebarContent .account-info .account-mobile .logout-mobile {
    flex: 1;
  }
  .navbar_main .sideBar .sidebarContent .account-info .account-mobile .login-mobile {
    flex: 1;
  }
  .navbar_main .sideBar .close {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 8vw;
    color: #c7b199;
    cursor: pointer; /* Add cursor style for better usability */
  }
  .navbar_main .main_menu {
    padding-bottom: 0;
  }
  .navbar_main .main_menu .menu-icons {
    justify-content: space-between;
  }
  .navbar_main .main_menu .menu-icons .left-menu {
    flex: 4;
  }
  .navbar_main .main_menu .menu-icons .left-menu .mobile-menu-icon {
    display: block;
    font-size: 4vw;
  }
  .navbar_main .main_menu .menu-icons .left-menu .search {
    gap: 2vw;
  }
  .navbar_main .main_menu .menu-icons .left-menu .search .search_icon img {
    width: 4vw;
  }
  .navbar_main .main_menu .menu-icons .left-menu .search .search-input {
    width: 18vw;
  }
  .navbar_main .main_menu .menu-icons .left-menu .search .search-input::-moz-placeholder {
    font-size: 3vw;
  }
  .navbar_main .main_menu .menu-icons .left-menu .search .search-input::placeholder {
    font-size: 3vw;
  }
  .navbar_main .main_menu .menu-icons .center-menu {
    flex: 4;
  }
  .navbar_main .main_menu .menu-icons .center-menu .menu-logo-container img {
    width: 12vw;
  }
  .navbar_main .main_menu .menu-icons .center-menu .menu-list {
    display: none;
  }
  .navbar_main .main_menu .menu-icons .right-menu {
    flex: 0.5;
  }
  .navbar_main .main_menu .menu-icons .right-menu .cart_icon img {
    width: 4vw;
  }
  .navbar_main .main_menu .menu-icons .right-menu .user_box {
    display: none;
  }
}
@media (orientation: portrait) {
  .navbar_main .main_menu .menu-icons .center-menu .menu-logo-container img {
    width: 18vw;
  }
  .navbar_main .main_menu .menu-icons .left-menu {
    gap: 5vw;
    padding-top: 5vw;
  }
  .navbar_main .main_menu .menu-icons .left-menu .mobile-menu-icon {
    font-size: 5vw;
  }
  .navbar_main .main_menu .menu-icons .left-menu .search .search_icon img {
    width: 5vw;
  }
  .navbar_main .main_menu .menu-icons .right-menu {
    padding-top: 5vw;
  }
  .navbar_main .main_menu .menu-icons .right-menu .cart_icon img {
    width: 5vw;
  }
}/*# sourceMappingURL=AppBar.css.map */