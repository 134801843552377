.adminNavbar {
  display: flex;
  gap: 2vw;
  padding: 2vw;
  background-color: whitesmoke;
  color: #251a02;
  position: relative;
  z-index: 100;
}
.adminNavbar .menu-logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1vw;
  position: sticky;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0);
}
.adminNavbar .menu-logo-container img {
  width: 100px;
}
.adminNavbar .menu-list {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2vw;
}
.adminNavbar .menu-list a {
  text-decoration: none;
  color: #251a02;
  font-size: 1.5vw;
}
@media (max-width: 768px) {
  .adminNavbar .menu-list a {
    font-size: 12px;
  }
}
.adminNavbar .menu-list .active {
  color: #c7b199;
}
.adminNavbar .user {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  top: 40px;
  right: 30px;
}
@media (max-width: 768px) {
  .adminNavbar .user {
    top: 10px;
    right: 10px;
  }
}
@media (max-width: 900px) {
  .adminNavbar .user {
    top: 20px;
    right: 15px;
  }
}
.adminNavbar .user .user-icon {
  font-size: 25px;
}
@media (max-width: 768px) {
  .adminNavbar .user .user-icon {
    font-size: 12px;
  }
}
.adminNavbar .user .logout-btn, .adminNavbar .user .login-btn {
  cursor: pointer;
  padding: 5px 10px;
  font-size: 12px;
}
@media (max-width: 768px) {
  .adminNavbar .user .logout-btn, .adminNavbar .user .login-btn {
    font-size: 8px;
    font-weight: bold;
    padding: 2.5px 5px;
  }
}
.adminNavbar .user .admin-email {
  font-size: 16px;
}
@media (max-width: 768px) {
  .adminNavbar .user .admin-email {
    font-size: 8px;
    font-weight: bold;
  }
}/*# sourceMappingURL=AdminNavBar.css.map */