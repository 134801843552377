.shop-container {
  margin-top: 2vw;
  position: relative;
}
.shop-container .logo2Container {
  text-align: center;
}
.shop-container .logo2Container .logo2 {
  width: 12vw;
  opacity: 0.4;
}
.shop-container .shopTitle {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2vw;
}

.shopCardContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3vw;
  gap: 4vw;
  flex-wrap: wrap;
}
.shopCardContainer .shopCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5vw;
  font-size: 1.5vw;
  cursor: pointer;
  position: relative;
}
.shopCardContainer .shopCard .outOfStock {
  pointer-events: none;
  opacity: 0.6; /* Adjust as needed */
}
.shopCardContainer .shopCard .outOfStockOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  z-index: 1;
}
.shopCardContainer .shopCard .shopImageCard {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5vw;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
}
.shopCardContainer .shopCard .shopImageCard .shopImage {
  width: 18vw;
  height: 21vw;
  -o-object-fit: cover;
     object-fit: cover;
}
.shopCardContainer .shopCard .shopItemTitle {
  padding-top: 1vw;
}
.shopCardContainer .shopCard .shopItemPriceContainer {
  display: flex;
  gap: 1vw;
  align-items: center;
}
.shopCardContainer .shopCard .shopItemPriceContainer .shopItemOriPrice {
  font-size: 1.25vw;
}

@media (max-width: 768px) {
  .shop-container .logo2Container .logo2 {
    width: 20vw;
  }
  .shop-container .shopTitle {
    font-size: 3vw;
  }
  .shopCardContainer {
    padding: 3vw 0;
    gap: 5vw;
  }
  .shopCardContainer .shopCard {
    gap: 1.5vw;
    font-size: 2.5vw;
  }
  .shopCardContainer .shopCard .shopImageCard {
    box-shadow: none;
    border: 0.5px solid #c7b199;
    border-radius: 5vw;
    overflow: hidden;
  }
  .shopCardContainer .shopCard .shopImageCard .shopImage {
    width: 42vw;
    height: 49vw;
  }
  .shopCardContainer .shopCard .shopItemPriceContainer .shopItemOriPrice {
    font-size: 2.25vw;
  }
}/*# sourceMappingURL=Shop.css.map */