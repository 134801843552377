.paymentCancelContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 25vh;
  gap: 2vh;
  background-color: #FFF3E0;
  color: #F57C00;
  height: 75vh;
  border-radius: 8px;
}/*# sourceMappingURL=PaymentCancel.css.map */